<template>
  <div class="error-page">
    <div class="error-page-img">
      <img class="img" src="../assets/images/500.png" />
    </div>
    <div class="error-page-desc">哎呀，服务器开小差啦</div>
    <!-- <div class="error-page-btn">
			<button class="btn-blue-transparent" @click="goBack">返回</button>
		</div> -->
  </div>
</template>

<script>
export default {
  name: 'Error500',
  methods: {
    goBack() {
      this.$router.back()
    }
  }
}
</script>
